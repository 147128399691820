import React, {useEffect, useState} from "react";

export const LatestPodcasts = ({maxPods = 20, style = "full"}) => {
    const [podcasts, setPodcasts] = useState([]);

    function decodeHtmlEntities(str) {
        var textArea = document.createElement("textarea");
        textArea.innerHTML = str;
        return textArea.value;
    }

    async function fetchPodcasts() {
        try {
            const res = await fetch(`${process.env.API_URL}/podcasts/${maxPods}/0`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY,
                },
            });
            if (res.status === 200) {
                const data = await res.json();
                const lp = [];
                data.items.forEach((podcasts) => {
                    let title = decodeHtmlEntities(podcasts.name);
                    let media = podcasts.images[1].url;
                    let bigmedia = podcasts.images[0].url;
                    const dateObject = new Date(podcasts.release_date);
                    let dte = dateObject.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "long",
                    });
                    let link = podcasts.uri.replace("spotify:episode:", "");
                    lp.push({
                        title: title,
                        media: media,
                        bigmedia: bigmedia,
                        date: dte,
                        link: link,
                    });
                });
                setPodcasts(lp);
            } else {
                //console.error('Failed to fetch scorecard:', res.status);
            }
        } catch (error) {
            //console.error('Network error:', error);
        }
    }

    useEffect(() => {
        fetchPodcasts();
    }, []);

    return (
        <>
            {style === "summary" ? (
                <>
                    {podcasts.length > 0 ? (
                        <div className="w-full grid grid-cols-2 gap-2 lg:grid lg:grid-cols-4 lg:gap-4">
                            {podcasts.map((podcast, index) => {
                                return (
                                    <div key={index} className="w-full h-full lg:h-full">
                                        <a href={`/podcasts?id=${podcast.link}`}>
                                            <div
                                                className="w-full h-[30px] flex flex-row justify-between items-center bg-mainB">
                                                <div
                                                    className="bg-lime font-anton flex justify-center items-center
                    text-main w-[64px] h-[30px] text-[14px] leading-[15px]"
                                                >
                                                    PODCAST
                                                </div>
                                                <div
                                                    className="bg-opacity-15 font-chakra normal-case text-white text-right text-[12px] mr-2">
                                                    {podcast.date}
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <img
                                                    src={podcast.media}
                                                    className="w-full h-full"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div
                                                className="mt-2 font-anton text-[15px] lg:text-[18px] leading-[22px] font-400">
                                                {podcast.title}
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </>
            ) : (
                <div className="md:flex md:flex-wrap gap-4">
                    {podcasts.slice(0, maxPods).map((podcast, index) => (
                        <div className="w-full md:w-[49%] relative">
                            <a href={podcast.link}>
                                <img
                                    src={podcast.media}
                                    className="w-[100%] h-[350px] object-cover"
                                    loading="lazy"
                                />
                                <div
                                    className="absolute bottom-0 md:top-0 left-0 w-full h-full flex flex-col justify-between">
                                    <div
                                        className="absolute bottom-[31px] md:top-0 left-0 w-[50px] h-[30px] bg-white anton text-black text-14px pt-[8px] pl-[10px]">
                                        PODCASTS
                                    </div>
                                    <div
                                        className="absolute bottom-[31px] md:top-0 left-[50px] h-[30px] bg-main anton text-white text-14px pt-[8px] pl-[10px] pr-[10px]">
                                        {podcast.date}
                                    </div>
                                    <div
                                        className="absolute w-[90%] h-[30px] md:h-[38px] bottom-[1px] md:top-[30px] text-[16px] md:text-[20px] left-0 text-black p-2 bg-lime truncate">
                                        {podcast.title}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
export default LatestPodcasts;
